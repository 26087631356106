import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { isNil } from '@trimble-gcs/common';
import {
  ConnectProject,
  ConnectUser,
  IFormattingSettings,
  ProjectSettings,
} from 'trimble-connect-workspace-api';
import { UomPreference } from '../../api';
import { ConnectWorkspace } from '../../connect';
import { UomConverter } from '../../shared';
import {
  SetProject,
  SetProjectSettings,
  SetSettings,
  SetUomConverter,
  SetUser,
  SetWorkspace,
} from './app.actions';
import { AppSettings } from './app.models';

export interface AppStateModel {
  settings: AppSettings;
  user: ConnectUser;
  project: ConnectProject;
  projectSettings: ProjectSettings;
  uomConverter: UomConverter;
  workspace: ConnectWorkspace;
}

@State<AppStateModel>({
  name: 'appState',
})
@Injectable()
export class AppState {
  @Selector() static settings(state: AppStateModel) {
    return state.settings;
  }

  @Selector() static user(state: AppStateModel) {
    return state.user;
  }

  @Selector() static project(state: AppStateModel) {
    return state.project;
  }

  @Selector() static projectSettings(state: AppStateModel) {
    return state.projectSettings;
  }

  @Selector() static uomPreference(state: AppStateModel) {
    const formatting = state.projectSettings.formatting as Required<IFormattingSettings>;
    const uomPreference: UomPreference = {
      linearUnit: formatting.lengthUnit,
      linearDecimals: formatting.lengthDecimals,
    };
    return uomPreference;
  }

  @Selector() static uomConverter(state: AppStateModel) {
    return state.uomConverter;
  }

  @Selector() static workspace(state: AppStateModel) {
    if (isNil(state.workspace)) {
      throw new Error('Connect workspace not initialized. Call ConnectService.initialize() first.');
    }

    return state.workspace;
  }

  @Action(SetSettings) setSettings(ctx: StateContext<AppStateModel>, action: SetSettings) {
    ctx.patchState({ settings: action.appSettings });
  }

  @Action(SetUser) setUser(ctx: StateContext<AppStateModel>, action: SetUser) {
    ctx.patchState({ user: action.user });
  }

  @Action(SetProject) setProject(ctx: StateContext<AppStateModel>, action: SetProject) {
    ctx.patchState({ project: action.project });
  }

  @Action(SetProjectSettings) setProjectSettings(
    ctx: StateContext<AppStateModel>,
    action: SetProjectSettings,
  ) {
    ctx.patchState({ projectSettings: action.projectSettings });
  }

  @Action(SetUomConverter) setUomConverter(
    ctx: StateContext<AppStateModel>,
    action: SetUomConverter,
  ) {
    ctx.patchState({ uomConverter: action.uomConverter });
  }

  @Action(SetWorkspace) setWorkspace(ctx: StateContext<AppStateModel>, action: SetWorkspace) {
    ctx.patchState({ workspace: action.workspace });
  }
}
