import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_TABS_CONFIG, MatTabsConfig } from '@angular/material/tabs';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { withNgxsStoragePlugin } from '@ngxs/storage-plugin';
import { provideStore } from '@ngxs/store';
import { appRoutes } from './app.routes';
import { tokenInterceptor } from './interceptors';
import {
  ConsoleLogger,
  LOGGER_SERVICE_LOGGERS,
  LOGGER_SERVICE_NAME,
  loggingInterceptor,
} from './logging';
import { WINDOW, windowProvider } from './services/window.provider';
import {
  AlignmentState,
  AppService,
  AppState,
  AuthState,
  DisplaySettingState,
  NavigationState,
  SourceFileState,
} from './state';

const tabConfig: MatTabsConfig = {
  animationDuration: '0',
  preserveContent: true,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(appRoutes),
    provideHttpClient(withInterceptors([tokenInterceptor, loggingInterceptor])),
    provideStore(
      [AppState, AuthState, AlignmentState, SourceFileState, DisplaySettingState, NavigationState],
      withNgxsStoragePlugin({ keys: [AuthState] }),
    ),
    importProvidersFrom(MatDialogModule),
    windowProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppService, WINDOW],
      multi: true,
    },
    { provide: MAT_TABS_CONFIG, useValue: tabConfig },
    { provide: LOGGER_SERVICE_NAME, useValue: 'LoggerService' },
    { provide: LOGGER_SERVICE_LOGGERS, useClass: ConsoleLogger, multi: true },
  ],
};

function initializeAppFactory(appService: AppService, window: Window) {
  return () => appService.initialize(window);
}
