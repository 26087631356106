import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { TierResult, getGPUTier } from 'detect-gpu';
import { catchError, filter, from, map, of, take, tap } from 'rxjs';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { injectLogger } from '../logging';
import { AppState } from '../state';
import { initializeAptrinsic } from './aptrinsic.init';
import { AptrinsicSdk } from './aptrinsic.sdk';
import { GainsightEvents } from './gainisght-events';
import { ProjectInfo } from './gainsight.models';

// https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK

@Injectable({
  providedIn: 'root',
})
export class GainsightService {
  private readonly store = inject(Store);

  readonly sdk = inject(AptrinsicSdk);
  readonly logger = injectLogger('GainsightService');

  initialize(window: Window, tag: string) {
    initializeAptrinsic(window, tag);
  }

  identifyUser() {
    return this.store.select(AppState.user).pipe(
      filter(isDefined),
      map((connectUser) => {
        const apptrinsicUser: aptrinsic.user = {
          id: connectUser.id,
          email: connectUser.email ?? '',
          firstName: connectUser.firstName ?? '',
          lastName: connectUser.lastName ?? '',
          language: connectUser.language ?? '',
          role: '',
          connectId: connectUser.id,
        };
        return apptrinsicUser;
      }),
      tap((user) => this.sdk.identify(user, { id: user.id })),
      take(1),
    );
  }

  trackProject() {
    return this.store.select(AppState.project).pipe(
      filter(isDefined),
      map((project) => this.getProjectInfo(project)),
      tap((projectInfo: ProjectInfo) => {
        this.sdk.track(GainsightEvents.projectSelected, projectInfo);
      }),
      take(1),
    );
  }

  detectGpu() {
    return from(getGPUTier()).pipe(
      catchError((error) => {
        this.logger.info('GPU detection failed', {}, error);
        return of({
          tier: 0,
          type: 'WEBGL_UNSUPPORTED',
        } satisfies TierResult);
      }),
      tap((result: TierResult) => {
        this.sdk.track(GainsightEvents.gpuDetected, result);
      }),
    );
  }

  private getProjectInfo(project: ConnectProject) {
    return {
      projectId: project.id,
      projectName: project.name ?? '',
    } satisfies ProjectInfo;
  }
}
