import { ConnectProject, ConnectUser, ProjectSettings } from 'trimble-connect-workspace-api';
import { ConnectWorkspace } from '../../connect';
import { UomConverter } from '../../shared';
import { AppSettings } from './app.models';

export class SetSettings {
  static readonly type = '[AppState] SetSettings';
  constructor(public readonly appSettings: AppSettings) {}
}

export class SetUser {
  static readonly type = '[AppState] SetUser';
  constructor(public readonly user: ConnectUser) {}
}

export class SetProject {
  static readonly type = '[AppState] SetProject';
  constructor(public readonly project: ConnectProject) {}
}

export class SetProjectSettings {
  static readonly type = '[AppState] SetProjectSettings';
  constructor(public readonly projectSettings: ProjectSettings) {}
}

export class SetUomConverter {
  static readonly type = '[AppState] SetUomConverter';
  constructor(public readonly uomConverter: UomConverter) {}
}

export class SetWorkspace {
  static readonly type = '[AppState] SetWorkspace';
  constructor(public readonly workspace: ConnectWorkspace) {}
}
